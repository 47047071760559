import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const HomePageTemplate = ({
  heading,
  description,
  subline,
  meta_title,
  meta_description,
  claim,
  claim_details,
  emergency_intro,
  more_info,
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-primary is-bold is-medium homepage'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section' />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='yellow-border' />
    <section className='section section--gradient without-vertical-padding'>
      <div className='container'>
        <div className='section'>
          <div className='columns'>
            <div className='column is-12'>
              <div className='content'>
                <div>
                  <h3 className='extra-light is-size-2 anthracite-text'>
                    {heading}
                  </h3>
                  <hr className='divider' />
                  <div>
                    <p className='anthracite-text'>
                      <span className='bold'>{subline}</span><br />
                      {description}
                    </p>
                  </div>
                  <br />
                  <div>
                    <p className='anthracite-text'>
                      <span className='bold'>{claim}</span><br />
                      {claim_details}<br />
                      <span className='bold'>{emergency_intro}</span>
                    </p>
                  </div>
                  <br />
                  <p className='anthracite-text'>{more_info}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

HomePageTemplate.propTypes = {
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  subline: PropTypes.string,
  claim: PropTypes.string,
  claim_details: PropTypes.string,
  emergency_intro: PropTypes.string,
  more_info: PropTypes.string,
}

export default HomePageTemplate
